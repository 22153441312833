import Vue from 'vue'
import Vuex from 'vuex'
import modules from '../modules'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    modules,
    state: {
        old_service: process.env.VUE_APP_OLD_MY_ACCOUNT,
        service: process.env.VUE_APP_MY_ACCOUNT,
        api_gateway: process.env.VUE_APP_GATEWAY,
        account_service: process.env.VUE_APP_ACCOUNT_SERVICE,
        utils: process.env.VUE_APP_UTILS_SERVICE,
        client: process.env.VUE_APP_CLIENT,
        version: process.env.VUE_APP_VERSION,
        customer_portal: process.env.VUE_APP_CUSTOMER_PORTAL,
        brm: process.env.VUE_APP_BRM,
        bdl: process.env.VUE_APP_BDL,
        access: process.env.VUE_APP_ACCESS,
        administration_app: process.env.VUE_APP_ADMIN,
        sso_domain: process.env.VUE_APP_SSO_DOMAIN,
        captcha: process.env.VUE_RECAPTHA,
        build: process.env.VUE_APP_BUILD,
        revision: process.env.VUE_APP_REVISION,
        telco: process.env.VUE_APP_TELCO,
        errorDialog: false,
        error: {
            text: 'Test',
            code: ''
        },
        snackbarNotification: {
            status: false,
            text: ''
        },
        loginErrorMessage: false,
        loginErrorText: '',
        badsetupcode: false,
        localToken: '',
        firstLogin: '',
        loader: true,
        theme: {
            dark: false
        }
    },
    getters: {
        account_app_url: (state) => {
            return state.customer_portal
        },
        access_app_url: (state) => {
            return state.access
        },
        bdl_app_url: (state) => {
            return state.bdl
        },
        brm_app_url: (state) => {
            return state.brm
        },
        administration_app_url: (state) => {
            return state.administration_app
        },
        token: () => {
            return localStorage.getItem('access-storage')
        },
        user: () => {
            return JSON.parse(localStorage.getItem('user-storage'))
        },
        userRole: (state) => {
            if (JSON.parse(localStorage.getItem('user-storage')) != null) {
                let role = (state.user.type = JSON.parse(localStorage.getItem('user-storage')).type)

                return role
            }
        },
        themeSettings: () => {
            return JSON.parse(localStorage.getItem('-@user'))
        },
        sso_token: () => {
            var name = 'sso-token='
            var decodedCookie = decodeURIComponent(document.cookie)
            var ca = decodedCookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length)
                }
            }
            return ''
        }
    },
    mutations: {
        toggleErrorModal(state, payload) {
            state.errorDialog = !state.errorDialog
            state.error.code = payload.status
            state.error.text = payload.error
        },
        closeError(state) {
            state.errorDialog = !state.errorDialog
        },
        setErrorMessage(state, payload) {
            state.loginErrorText = payload
        },
        onLoginClearSession() {
            localStorage.removeItem('user-storage')
            localStorage.removeItem('access-storage')
        },
        setThemeSettings(state) {
            state.theme.dark = !state.theme.dark
            let storage = JSON.stringify(state.theme)

            localStorage.setItem('-@user', storage)
        },
        setInitialThemeSettings(state, payload) {
            state.theme.dark = payload
        },
        setNotificationAction(state, payload) {
            state.snackbarNotification = {
                status: true,
                text: payload
            }
        },
        setLoader(state, payload) {
            state.loader = payload
        },
        badSetupGAcode(state) {
            state.badsetupcode = true
            state.loginErrorMessage = false
        },
        setBadSetupGAcode(state, payload) {
            state.badsetupcode = payload
        },
        toggleLoginModal() {
            router.push('/login').catch(() => {})
        },
        setLogin(state) {
            state.firstLogin = '0'
            router.push('/').catch(() => {})
        }
    },
    actions: {
        handleAPIError(context, error) {
            if (!error || !error.response) {
                context.commit('toggleLoginModal', {})
            }
            if (error.response.status === 401) {
                context.commit('toggleLoginModal', {})
            } else {
                context.commit('toggleErrorModal', error.response.data)
            }
        },
        handleLoginError(context, message) {
            context.commit('setErrorMessage', message.response.data.error)
            this.state.loginErrorMessage = true
            if (router.currentRoute.name != 'login' && router.currentRoute.name != 'home')
                router.push({ name: 'login' })
        },
        getInitialThemeSettings(context, payload) {
            context.commit('setInitialThemeSettings', payload)
        },
        notificationAction(context, payload) {
            context.commit('setNotificationAction', payload)
        },
        onLoginClearSession(context) {
            context.commit('onLoginClearSession')
        },
        setGaError(context) {
            context.commit('badSetupGAcode')
        },
        updateTheme(context) {
            context.commit('setThemeSettings')
        },
        setLoader(context, payload) {
            context.commit('setLoader', payload)
        },
        setBadSetupGAcode(context, payload) {
            context.commit('setBadSetupGAcode', payload)
        }
    }
})
