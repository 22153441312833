import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,
    state: {
        item: '',
        account: {},
        countries: [],
        registrationOk: '',
        first: true,
        userOk: false,
        recKey: '',
        qrcode: '',
        step: 1,
        verificationError: false,
        resetSuccess: false,
        activeStatus: true,
        resetFormStatus: true,
        user: {
            name: '',
            email: '',
            type: '',
            alias: '',
            id: ''
        },
        twoFACodeBadAttemptsLimit: false,
        recoveryStatus: false,
        recoveryStatusSuccess: false,
        redirect: {
            continue: '',
            path: ''
        }
    },
    mutations: {
        setAccount(state, payload) {
            state.account = payload
        },
        setCountries(state, payload) {
            if (payload) return (state.countries = payload)

            return (state.countries = [])
        },
        setRegistrationStatus(state, payload) {
            state.registrationOk = payload
        },
        setActive(state, payload) {
            state.activeStatus = payload
        },
        setFirst(state) {
            state.first = false
            state.userOk = true
            this.state.loginErrorMessage = false
        },
        setAuthError(state) {
            state.first = true
            state.userOk = false
        },
        accessStorage(state, payload) {
            localStorage.setItem('access-storage', payload.token)
        },
        twoFactorKey(state, payload) {
            state.recKey = payload
        },
        toggleLoginModal() {
            this.commit('clearSession')
            this.$router.push('/login').catch(() => {})
        },
        userStorage(state, payload) {
            let storage = JSON.stringify(payload)
            localStorage.setItem('user-storage', storage)

            let myDate = new Date()
            myDate.setMonth(myDate.getMonth() + 12)

            document.cookie =
                'sso-token' +
                '=' +
                localStorage.getItem('access-storage') +
                ';domain=' +
                'balkanac.net' +
                ';expires=' +
                myDate +
                ';path=/;'

            let userMeta = JSON.parse(localStorage.getItem('user-storage'))
            state.user.name = userMeta.name
            state.user.email = userMeta.email

            state.loginDialog = false

            state.firstLogin = userMeta.first_login

            return (window.location.href = 'https://admin.balkanac.net')
        },
        clearSession() {
            localStorage.removeItem('user-storage')
            localStorage.removeItem('access-storage')
            localStorage.removeItem('origin-path')
            document.cookie =
                'sso-token=;domain=' + this.state.sso_domain + ';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        },
        setStep(state, payload) {
            state.step = payload
        },
        setImage(state, payload) {
            state.qrcode = payload
        },
        badSetupGAcode(state, payload) {
            state.verificationError = payload
        },
        setTwoFACodeBadAttemptsLimit(state, payload) {
            state.twoFACodeBadAttemptsLimit = payload
        },
        setRecoveryStatus(state, payload) {
            state.recoveryStatus = payload
        },
        setRecoveryStatusSuccess(state, payload) {
            state.recoveryStatusSuccess = payload
        },
        setResetSuccess(state, payload) {
            state.resetSuccess = payload
        },
        hideForm(state, payload) {
            state.resetFormStatus = payload
        },
        setRedirect(state, payload) {
            if (payload) {
                state.redirect.continue = payload.continue
                state.redirect.path = payload.path
                return
            }

            return (window.location.href = 'https://admin.balkanac.net')
        }
    },
    actions: {
        login(context, payload) {
            return new axios.post(this.state.api_gateway + '/account/login', payload)
                .then((response) => {
                    let twoFactorStatus = response.data.code
                    if (twoFactorStatus === 1) {
                        this.state.localToken = response.data.token
                        context.commit('accessStorage', response.data)
                        context.commit('userStorage', response.data.account)
                    } else {
                        this.state.localToken = response.data.token
                        let recKey = response.data.recovery
                        context.commit('accessStorage', response.data)
                        context.commit('twoFactorKey', recKey)
                        router.push({ name: 'verification' })
                    }
                    this.dispatch('setBadSetupGAcode', false)
                })
                .catch((err) => {
                    this.dispatch('handleLoginError', err)
                })
        },
        register(context, payload) {
            return new axios.post(this.state.api_gateway + '/account/register', payload)
                .then((response) => {
                    context.commit('setRegistrationStatus', true)
                })
                .catch((err) => {
                    this.dispatch('handleAPIError', err)
                    context.commit('setRegistrationStatus', false)
                })
        },
        setRedirect(context, payload) {
            context.commit('setRedirect', payload)
        },
        doTwoFactorAuth(context, payload) {
            return new axios.get(this.state.api_gateway + '/account/authcode/' + payload.gauthcode, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-storage')
                }
            })
                .then((response) => {
                    context.commit('userStorage', response.data)
                })
                .catch((err) => {
                    if (payload.confirm) {
                        if (err.response.status === 401) {
                            context.commit('badSetupGAcode', true)
                            context.commit('setTwoFACodeBadAttemptsLimit', false)
                        } else {
                            context.commit('setTwoFACodeBadAttemptsLimit', true)
                        }
                    } else {
                        if (err.response.status === 401) {
                            this.dispatch('setGaError')
                            context.commit('setTwoFACodeBadAttemptsLimit', false)
                        } else {
                            context.commit('setTwoFACodeBadAttemptsLimit', true)
                        }
                    }
                })
        },
        logout(context) {
            return new axios.get(this.state.api_gateway + '/account/logout', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-storage')
                }
            })
                .then(() => {
                    context.commit('clearSession')
                })
                .catch((err) => {
                    this.dispatch('handleLoginError', err)
                })
        },
        authconfirm(context, payload) {
            return new axios.get(this.state.api_gateway + '/account/authconfirm/' + payload, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-storage')
                }
            })
                .then((response) => {
                    let image = response.data.image
                    context.commit('setImage', image)
                    context.commit('setStep', 3)
                })
                .catch((err) => {
                    this.dispatch('handleLoginError', err)
                })
        },
        recovery(context, payload) {
            return new axios.get(
                this.state.api_gateway +
                    '/account/recover?recovery=' +
                    payload.recoveryKey +
                    '&username=' +
                    payload.email
            )
                .then(() => {
                    context.commit('setRecoveryStatusSuccess', true)
                    context.commit('setAuthError')
                    router.push('/login').catch(() => {})
                })
                .catch(() => {
                    context.commit('setRecoveryStatus', true)
                })
        },
        forgotRequest(context, payload) {
            return new axios.put(this.state.api_gateway + '/account/forgot-password/request', payload, {})
                .then((response) => {
                    context.commit('setResetSuccess', true)
                })
                .catch((err) => {
                    this.dispatch('handleAPIError', err)
                })
        },
        reset(context, payload) {
            return new axios.put(this.state.api_gateway + '/account/forgot-password', payload, {
                headers: {
                    Authorization: 'Bearer ' + this.getters.token
                }
            })
                .then((response) => {
                    this.dispatch('notificationAction', response.data.message)
                    context.commit('setResetSuccess', true)
                    context.commit('clearSession')
                })
                .catch((err) => {
                    this.dispatch('handleAPIError', err)
                })
        },
        activate(context, payload) {
            return new axios.put(this.state.api_gateway + '/account/confirm-email', payload, {
                headers: {
                    Authorization: 'Bearer ' + this.getters.token
                }
            })
                .then((response) => {
                    if (response.data.status == 'ok') {
                        context.commit('setActive', true)
                    } else {
                        context.commit('setActive', false)
                    }
                })
                .catch((err) => {
                    context.commit('setActive', false)
                    this.dispatch('handleAPIError', err)
                })
        },
        checkCode(context, payload) {
            return new axios.get(this.state.api_gateway + '/account/forgot-password/' + payload, {})
                .then(() => {
                    context.commit('hideForm', true)
                })
                .catch((err) => {
                    context.commit('hideForm', false)
                    this.dispatch('handleAPIError', err)
                })
        },
        countries(context) {
            return new axios.get(this.state.api_gateway + '/utils/countriesdata', {
                headers: {
                    Authorization: 'Bearer ' + this.getters.token
                }
            })
                .then((response) => {
                    context.commit('setCountries', response.data)
                })
                .catch((err) => {
                    this.dispatch('handleAPIError', err)
                })
        },
        checkToken(context) {
            if (this.getters.sso_token == '') {
                this.dispatch('setLoader', false)

                return
            }
            return new Promise((resolve, reject) => {
                axios
                    .get(this.state.api_gateway + '/account/checktoken', {
                        headers: {
                            Authorization: 'Bearer ' + this.getters.sso_token
                        }
                    })
                    .then((response) => {
                        this.dispatch('setLoader', false)
                        context.commit('setAccount', response.data.account)
                        window.location.href = 'https://admin.balkanac.net'
                        resolve(response)
                    })
                    .catch((err) => {
                        context.commit('clearSession')
                        this.dispatch('setLoader', false)
                        router.push('/').catch(() => {})
                        reject(err)
                    })
            })
        },
        redirectLogin(context) {
            context.commit('clearSession')
            context.commit('setTwoFACodeBadAttemptsLimit', false)
            context.commit('badSetupGAcode', false)
            context.commit('setStep', 1)
            router.push({ name: 'login' })
        }
    }
}
