<template>
    <v-main :class="checkDarkTheme() ? 'dark-theme' : 'light-theme'">
        <v-container class="fill-height" fluid>
            <v-row>
                <v-col cols="12" md="6" offset-md="3">
                    <div v-if="first">
                        <div class="fields">
                            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validateForm">
                                <v-text-field filled v-model="logindata.username" label="Email"></v-text-field>
                                <v-text-field
                                    messages="true"
                                    :rules="passwordRules"
                                    v-model="logindata.password"
                                    label="Password"
                                    filled
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show1 = !show1"
                                    :type="show1 ? 'text' : 'password'"
                                    autocomplete="new-password"
                                >
                                    <template v-slot:message>
                                        <div class="forgot_password_link">
                                            <router-link to="/forgot-password">Zaboravili ste lozinku?</router-link>
                                        </div>
                                    </template>
                                </v-text-field>

                                <div type="error" class="error-message" v-if="loginError">
                                    {{ $store.state.loginErrorText }}
                                </div>

                                <div type="error" class="success-message" v-if="badrecovery">
                                    2-Step Verifikacija je resetovana!
                                </div>

                                <v-btn
                                    class="submit-button"
                                    @click="login"
                                    color="primary"
                                    type="submit"
                                    block
                                    depressed
                                >
                                    Loguj se
                                </v-btn>
                            </v-form>
                        </div>
                        <v-divider></v-divider>
                        <div class="other-actions-section">
                            <div><router-link to="/register">Registriraj novi račun</router-link></div>
                        </div>
                        <div class="other-actions-section">
                            <div>
                                <a href="https://balkanac.net">Vrati se na početnu stranicu</a>
                            </div>
                        </div>
                    </div>
                    <div class="login-card" v-if="userOk">
                        <div class="logo verification">
                            <h2>Verifikacioni korak</h2>
                            <p>
                                Molimo unesite kod sa Google Authenticator aplikacije koja se nalazi na vasem mobilnom
                                uređajui pritisnite Send.
                            </p>
                        </div>
                        <div class="fields">
                            <v-form ref="form2" v-model="gauthValid" lazy-validation @submit.prevent="validateForm2">
                                <v-text-field
                                    v-model="gauthcode"
                                    ref="focusText"
                                    :rules="onlyNumbers"
                                    type="number"
                                    label="2-Step Verification Code"
                                    :disabled="badsetupcode"
                                    outlined
                                ></v-text-field>
                                <div type="error" class="error-message" v-if="twoFACodeBadAttemptsLimit">
                                    Invalidan 2-Step Verifikacioni kod.
                                </div>
                                <div type="error" class="error-message" v-if="badsetupcode">
                                    Dostigli ste maksimalan broj pokušaja! <br />
                                    Molimo ponovo se logujte.
                                </div>
                                <v-btn
                                    class="submit-button"
                                    @click="gauth"
                                    type="submit"
                                    color="primary"
                                    block
                                    depressed
                                    v-if="!badsetupcode"
                                    >Potvrdi</v-btn
                                >
                                <v-btn
                                    class="submit-button"
                                    @click="redirectLogin"
                                    type="submit"
                                    color="primary"
                                    block
                                    depressed
                                    v-if="badsetupcode"
                                    >Loguj se</v-btn
                                >
                                <span class="terms" v-if="badsetupcode"
                                    >Ako ne uspijete riješiti problem, kontaktirajte nas.</span
                                >
                            </v-form>
                        </div>
                        <v-divider></v-divider>
                        <div class="info-section">
                            Izgubili ste 2-Step Verifikacioni kod?
                            <router-link to="/recover">Recover ključa</router-link> ili
                            <a @click="resetFormField">Nazad</a>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-dialog v-model="showError" persistent max-width="500">
                <v-card>
                    <v-card-title>Problem na servisu </v-card-title>
                    <v-card-text>{{ $store.state.error.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="closeError">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            show1: false,
            valid: true,
            gauthValid: true,
            onlyNumbers: [
                (x) => !!x || 'This field is required',
                (x) => (x && x.length == 6) || 'Input must be exactly 6 digits'
            ],
            passwordRules: [
                (v) => !!v || 'Password is required',
                (v) => (v && v.length >= 1) || 'Please enter your password'
            ],
            logindata: {
                username: '',
                password: ''
            },
            gauthcode: ''
        }
    },
    watch: {
        userOk: function (value) {
            if (value === true) {
                setTimeout(() => {
                    this.$refs.focusText.focus()
                }, 500)
            }
        }
    },
    created() {
        if (this.$store.getters.sso_token == '') {
            this.$store.commit('login/clearSession')
        }
    },
    computed: {
        first() {
            return this.$store.state.login.first
        },
        userOk() {
            return this.$store.state.login.userOk
        },
        loginError() {
            return this.$store.state.loginErrorMessage
        },
        badsetupcode() {
            return this.$store.state.badsetupcode
        },
        twoFACodeBadAttemptsLimit() {
            return this.$store.state.login.twoFACodeBadAttemptsLimit
        },
        showError() {
            return this.$store.state.errorDialog
        },
        badrecovery() {
            return this.$store.state.login.recoveryStatusSuccess
        },
        oldservice() {
            return this.$store.state.old_service
        }
    },
    destroyed() {
        this.resetFormField()
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.logindata.username = this.logindata.username.replace(/\s/g, '')
                this.$store.dispatch('login/login', this.logindata)
            }
        },
        validateForm() {
            if (this.$refs.form.validate()) {
                return true
            }
        },
        validateForm2() {
            if (this.$refs.form2.validate()) {
                return true
            }
        },
        gauth() {
            if (this.$refs.form2.validate()) {
                this.$store.dispatch('login/doTwoFactorAuth', { gauthcode: this.gauthcode })
            }
        },
        redirectLogin() {
            this.resetFormField()
            this.$store.dispatch('onLoginClearSession')
            this.$store.dispatch('login/redirectLogin')
        },
        closeError() {
            this.$store.commit('closeError')
        },
        resetFormField() {
            this.logindata.username = ''
            this.logindata.password = ''
            this.gauthcode = ''
            this.valid = true
            this.$store.commit('login/setAuthError')
            this.$store.commit('setBadSetupGAcode', false)
            this.$store.commit('login/setTwoFACodeBadAttemptsLimit', false)
        }
    }
}
</script>

<style scoped>
.forgot_password_link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}
.fields {
    margin-top: 25px;
    margin-bottom: 10px;
}

.fields span.terms {
    text-align: center;
    display: block;
    margin-top: 15px;
    color: #4c4c4c;
    font-size: 12px;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo h2 {
    color: #4c4c4c;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
}

.login-card {
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.other-actions-section {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
    display: flex;
    justify-content: space-evenly;
}

.info-section {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
}

.info-section .v-alert {
    margin-bottom: 0px;
}

.new-account {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
}

.v-btn.submit-button {
    padding: 15px 0px;
    height: auto;
}

.submit-button {
    height: auto !important;
    padding: 15px 0px !important;
}

.outer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.error-message {
    color: red;
    padding: 5px 0px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

.success-message {
    color: green;
    padding: 5px 0px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

.verification h2 {
    margin-bottom: 10px;
}

.verification p {
    font-size: 14px;
    margin-bottom: 0;
}

.dark-theme .login-card {
    padding: 30px;
    background: #1e1e1e;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.dark-theme .logo h2 {
    color: #fff;
}

.dark-theme .info-section {
    color: #fff;
}

.dark-theme .new-account {
    color: #fff;
}

.dark-theme .fields span.terms {
    color: #fff;
}

@media screen and (max-width: 475px) {
    .login-card {
        padding: 0px;
        box-shadow: none;
    }
}
</style>
