<template>
    <v-app id="vapp">
        <body
            id="kt_body"
            data-bs-spy="scroll"
            data-bs-target="#kt_landing_menu"
            class="bg-white position-relative app-blank"
        >
            <!--begin::Theme mode setup on page load-->
            <!--end::Theme mode setup on page load-->
            <!--begin::Root-->
            <div class="d-flex flex-column flex-root" id="kt_app_root">
                <!--begin::Header Section-->
                <div class="mb-0" id="home">
                    <!--begin::Wrapper-->
                    <div
                        class="
                            bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom
                            landing-dark-bg
                        "
                    >
                        <!--begin::Header-->
                        <div>
                            <b-navbar toggleable="lg" style="background-color: #17486c">
                                <div class="logo-holder logo-10">
                                    <a href="https://balkanac.net">
                                        <img
                                            v-bind:style="[isMobile() ? { width: '50%' } : { 'padding-left': '15px' }]"
                                            src="./assets/img/balkanac-logo/0.5x/logo-full-white0.5x.png"
                                        />
                                    </a>
                                </div>

                                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                                <b-collapse id="nav-collapse" v-bind:class="[isMobile() ? null : 'mainnav']" is-nav>
                                    <b-navbar-nav>
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://balkanac.net"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Zašto Mi</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <!--end::Menu item-->
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://balkanac.net"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Ideja portala</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <!--begin::Menu item-->
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://balkanac.net/"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Ponuda</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <!--end::Menu item-->
                                        <!--begin::Menu item-->

                                        <!--end::Menu item-->
                                        <!--begin::Menu item-->
                                        <!--end::Menu item-->
                                        <!--begin::Menu item-->
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://balkanac.net"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Usluga</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://login.balkanac.net/login"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Loguj se</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <div class="menu-item">
                                            <!--begin::Menu link-->
                                            <a
                                                class="menu-link nav-link py-3 px-4 px-xxl-6 animation"
                                                href="https://login.balkanac.net/register"
                                                data-kt-scroll-toggle="true"
                                                data-kt-drawer-dismiss="true"
                                                >Registriraj se</a
                                            >
                                            <!--end::Menu link-->
                                        </div>
                                        <!--end::Menu item-->
                                    </b-navbar-nav>
                                </b-collapse>
                            </b-navbar>
                        </div>
                        <!--end::Header-->
                        <!--begin::Landing hero-->
                        <!--end::Landing hero-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Curve bottom-->
                    <div class="landing-curve landing-dark-color mb-10 mb-lg-20">
                        <div style="width: 100%">
                            <v-fade-transition mode="out-in">
                                <Load v-if="loader"></Load>
                                <router-view v-else></router-view>
                            </v-fade-transition>
                        </div>
                    </div>
                    <!--end::Curve bottom-->
                </div>

                <!--end::Testimonials Section-->
                <!--begin::Footer Section-->
                <div class="mb-0">
                    <!--begin::Curve top-->
                    <div class="landing-curve landing-dark-color">
                        <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                    <!--end::Curve top-->
                    <!--begin::Wrapper-->
                    <div class="landing-dark-bg pt-20">
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Row-->
                            <div class="row py-10 py-lg-20">
                                <!--begin::Col-->
                                <div class="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                    <!--begin::Block-->
                                    <div class="rounded landing-dark-border p-9 mb-10">
                                        <!--begin::Title-->
                                        <h2 class="text-white">Kontaktirajte nas</h2>
                                        <!--end::Title-->
                                        <!--begin::Text-->
                                        <span class="fw-normal fs-4 text-gray-700">
                                            www.balkanac.net
                                            <br />
                                            Höhwiesen 1, 89134 Blaustein (Germany)
                                            <br />
                                            +49 152 040 688 58 (WhatsApp / Viber)
                                            <br />
                                            Email: info@balkanac.de
                                            <br />
                                            od Ponedjeljka do Petka:
                                            <br />
                                            8:30-16:00 sati
                                        </span>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Block-->
                                    <!--begin::Block-->

                                    <!--end::Block-->
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-lg-6 ps-lg-16">
                                    <!--begin::Navs-->
                                    <div class="d-flex justify-content-center">
                                        <!--begin::Links-->
                                        <div class="d-flex fw-semibold flex-column me-20">
                                            <!--begin::Subtitle-->
                                            <h4 class="fw-bold text-gray-400 mb-6">BALKANAC</h4>
                                            <!--end::Subtitle-->
                                            <!--begin::Link-->
                                            <a
                                                href="https://balkanac.net/cuvanjepodataka"
                                                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >Sigurnost Podataka</a
                                            >
                                            <!--end::Link-->
                                            <!--begin::Link-->
                                            ><a
                                                href="https://balkanac.net/impressum"
                                                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >Impressum</a
                                            >
                                            ><a
                                                href="https://balkanac.net/agb"
                                                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >AGB</a
                                            >

                                            <!--end::Link-->
                                            <!--begin::Link-->

                                            <!--end::Link-->
                                        </div>
                                        <!--end::Links-->
                                        <!--begin::Links-->
                                        <div class="d-flex fw-semibold flex-column ms-lg-20">
                                            <!--begin::Subtitle-->
                                            <h4 class="fw-bold text-gray-400 mb-6">Društvene mreže</h4>
                                            <!--end::Subtitle-->
                                            <!--begin::Link-->
                                            <a href="https://www.facebook.com/BalkanacPortal" class="mb-6">
                                                <img
                                                    src="assets/media/svg/brand-logos/facebook-4.svg"
                                                    class="h-20px me-2"
                                                    alt=""
                                                />
                                                <span class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                    >Facebook</span
                                                >
                                            </a>
                                            <!--end::Link-->
                                            <!--begin::Link-->
                                            <!--end::Link-->
                                            <!--begin::Link-->

                                            <!--end::Link-->
                                            <!--begin::Link-->
                                            <!--end::Link-->
                                            <!--begin::Link-->
                                            <a href="https://www.instagram.com/balkanacportal" class="mb-6">
                                                <img
                                                    src="assets/media/svg/brand-logos/instagram-2-1.svg"
                                                    class="h-20px me-2"
                                                    alt=""
                                                />
                                                <span class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                    >Instagram</span
                                                >
                                            </a>
                                            <!--end::Link-->
                                        </div>
                                        <!--end::Links-->
                                    </div>
                                    <!--end::Navs-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                        </div>
                        <!--end::Container-->
                        <!--begin::Separator-->

                        <!--end::Container-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Footer Section-->
            <!--begin::Scrolltop-->
            <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                            opacity="0.5"
                            x="13"
                            y="6"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
            <!--end::Scrolltop-->
            <!--end::Root-->
            <!--begin::Engage drawers-->
            <!--begin::Demos drawer-->

            <!--begin::Scrolltop-->
            <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                            opacity="0.5"
                            x="13"
                            y="6"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
        </body>
    </v-app>
</template>

<script>
import Load from '@/components/App/Load.vue'

export default {
    name: 'App',
    components: {
        Load
    },
    computed: {
        loader() {
            return this.$store.state.loader
        }
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    created() {
        console.log(this.$cookies.get('sso-token'))
        this.$http
            .get(this.$store.state.api_gateway + '/accounts/acc', {
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get('sso-token')
                }
            })
            .then(() => {
                window.location.href = 'https://admin.balkanac.net'
            })
            .catch(() => {
                this.$cookies.remove('sso-token')
                window.location.href = 'https://login.balkanac.net'
            })
    },
    watch: {
        '$route.query': {
            handler() {
                this.$store.dispatch('login/setRedirect', this.$route.query)
            }
        }
    }
}
</script>
<style scoped>
@import 'assets/plugins/global/plugins.bundle.css';
@import 'assets/css/style.bundle.css';
@import 'assets/css/google.fonts.css';

#location-label {
    visibility: visible;
}

.is-dirty #location-label {
    visibility: hidden;
}
.menu-item a {
    color: white;
}
.font {
    font-family: 'Merienda', Helvetica, Arial;
}
.logo-10 h3 {
    color: #ffffff;
    font-weight: 300;
    font-size: 50px;
    line-height: 0.65;
    font-family: 'Lobster', cursive;
}
.logo-10 h3::first-letter {
    font-size: 140%;
    color: orange;
}
.logo-10 p {
    font-size: 14px;
    margin-left: 50px;
    color: #f0e68c;
    font-weight: 400;
    text-transform: capitalize;
    font-style: italic;
    font-family: 'Mansalva', cursive;
}

.logo-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
}
.logo-container ul li {
    width: 300px;
    height: 120px;
    background: #fff;
    border-radius: 10px;
    margin: 10px;
    float: left;
    padding: 20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-container ul li a {
    text-decoration: none !important;
    display: inline-block;
}
.logo-holder {
    text-align: center;
}
.animation {
    box-shadow: inset 0 0 0 0 #17486c;
    color: white;
    font-size: 17px;
    margin-right: 7px;
    margin-left: 7px;
    transition: color 0.8s ease-in-out, box-shadow 0.8s ease-in-out;
    text-align: center;
}
.animation:hover {
    color: #17486c;
    box-shadow: inset 200px 0 0 0 white;
}
.mainnav {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
