import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueCookies from 'vue-cookies'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

Vue.use(VueCookies)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.mixin({
    methods: {
        checkDarkTheme() {
            if (!this.$store.getters.themeSettings) return false

            if (this.$store.getters.themeSettings.dark) true

            return false
        }
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    beforeCreate() {
        this.$options.created = this.$options.created || []
        this.$options.created.push(async function () {
            try {
                await store.dispatch('login/checkToken')
            } catch (error) {
                store.commit('toggleLoginModal', {})
            }
        })
    }
}).$mount('#app')
