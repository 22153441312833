import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'home',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/Register.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/Reset.vue')
    },
    {
        path: '/reset-password/:hash',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/NewPassword.vue')
    },
    {
        path: '/verification',
        name: 'verification',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/Verification.vue')
    },
    {
        path: '/confirm-email/:code/:email',
        name: 'confirm-email',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/Activation.vue')
    },
    {
        path: '/recover',
        name: 'recover',
        component: () => import(/* webpackChunkName: "register" */ '../views/Initial/Recover.vue')
    },
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "service-page" */ '../views/NotFound.vue')
    },
    {
        path: '/no-access',
        name: 'no-access',
        component: () => import(/* webpackChunkName: "no-access" */ '../views/AccessDenied.vue')
    },
    {
        path: '/customer_portal',
        name: 'customer_portal',
        beforeEnter() {
            window.open(store.state.customer_portal + '/', '_self')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}
router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        return next({ name: to.name, query: from.query })
    }

    next()
})
